.forgotPassword-main {
    font-family: var(--bs-body-font-family);
    min-height: 82vh;
}

.forgotPassword-main h2 {
    font-weight: normal;
    font-size: 2.5rem;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.login-main form {
    padding-top: 2rem;
}

.create-account {
    padding-left: 5px;
    text-decoration: none;
    color: #121212;
    cursor: pointer;
}

.create-account:hover {
    color: #121212;
    text-decoration: underline;
}

.remember-password {
    text-decoration: underline;
    color: #121212;
    cursor: pointer;
}

.remember-password:hover {
    color: #121212;
    text-decoration: none;
}