td,
th,
tr {
    font-size: 0.75rem;
    border: .5px solid black;
    text-align: center;
    vertical-align: middle;
    font-family: var(--bs-body-font-family);
}

table {
    width: 100%;
    border-collapse: collapse;
}

.topics-main td {
    text-align: left;
    padding: 15px;
    vertical-align: middle;
}

.subCategory {
    width: 50px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sub-categories {
    overflow-x: scroll;
    width: 100%
}

.topics-main .next-question {
    float: right;
}

.topics-main .sub-categories-table button:hover,
button:active,
button:focus-visible,
.btn.show {
    color: black !important;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none;
}

.sub-categories-table button,
button::after {
    background-color: transparent;
    border-color: transparent;
    color: black;
}

.sub-categories-table th,
.sub-categories-table td {
    padding: 0;
}

.subCategory .tooltiptext {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    /* Position the tooltip */
    left: 80px;
    position: absolute;
    z-index: 1;
}

.subCategory .th {
    left: auto;
    top: 100px;
    transform: translateX(-100%)
}

.subCategory:hover .tooltiptext {
    visibility: visible;
}

.sub-categories-table button::after {
    display: none;
}

.sub-cat-name {
    cursor: pointer;
}