.card-title {
    font-weight: bold;
}

.btn-secondary {
    background-color: #ccc;
    color: #333;
    border: none;
}

.subscriptions-main .btn-primary {
    background-color: var(--primary-color);
    border: none;
    cursor: pointer;
    padding: 0.5em 1em;
    border-radius: 10px;
    width: 100%;
}

/* .subscriptions-main .col-md-6,
.card-body {
    margin: 15px auto
} */

.subscriptions-main .card-title::after {
    display: block;
    height: 6px;
    width: 100%;
    margin-top: 5px;
    content: "";
    background: radial-gradient(ellipse at 50% -50%, rgba(0, 0, 0, .5) 0, rgba(255, 255, 255, 0) 65%);
}

.subscriptions-main .card-title {
    width: 100%;
}

.subscriptions-main .btn-primary:hover {
    background-color: var(--hover-color);
    border: none;
}

.subscriptions-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subscriptions-main p {
    margin: 0;
}

.subscriptions-main .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 15px;
    text-align: center;
}

.headline {
    font-family: var(--bs-body-font-family);
    font-weight: 700;
    letter-spacing: -0.042em;
    font-style: normal;
    text-align: center;
    font-size: 72px;
}

.subscription-card-main .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 0px 0px 12px 0px;
    margin: 10px 0;
    text-align: center;
    position: relative;
    cursor: pointer;
    border: none;
    /* box-shadow: 0 10px 15px -3px rgba(33, 150, 243, .4), 0 4px 6px -4px rgba(33, 150, 243, .4); */
    border-radius: 10px;
    background-color: #6B6ECC;
    background: linear-gradient(45deg, #04051dea 0%, #2b566e 100%);
}

.content {
    padding: 15px;
}

.content .price {
    color: white;
    font-weight: 800;
    font-size: 50px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.42);
}

.content .description {
    color: rgba(255, 255, 255, 0.6);
    margin-top: 10px;
    font-size: 14px;
    text-align: left;
}

.card .title {
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
    font-size: 1rem;
    padding: 10px 0;
    letter-spacing: 1px;
    width: 100%;
    border-radius: .5rem .5rem 0 0;
    background-color: var(--primary-color);
    font-family: var(--bs-body-font-family);
}

.subscription-card-main a,
.subscription-card-main button {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: none;
    outline: none;
    color: rgb(255 255 255);
    text-transform: uppercase;
    font-weight: 700;
    font-size: .75rem;
    padding: 0.75rem 1.5rem !important;
    background-color: var(--primary-color);
    border-radius: 0.5rem;
    width: 90% !important;
    text-shadow: 0px 4px 18px #2c3442;
}

.take-demo {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: none;
    outline: none;
    color: rgb(255 255 255);
    text-transform: uppercase;
    font-weight: 700;
    font-size: .75rem;
    padding: 0.75rem 1.5rem !important;
    background-color: var(--primary-color);
    border-radius: 0.5rem;
    text-shadow: 0px 4px 18px #2c3442;
}

.subscription-card-main span {
    width: 100%;
}

.subscription-card-main a:hover,
.subscription-card-main button:hover {
    background-color: var(--hover-color);
    transition: 400ms ease-in-out;
}