.login-main {
  font-family: var(--bs-body-font-family);
  min-height: 82vh;
}

.login-main h2 {
  font-weight: normal;
  font-size: 2.5rem;
  /* Increase the size of the heading */
  text-align: center;
  /* Center the heading */
  padding-top: 50px;
  /* Add padding below the heading */
  padding-bottom: 50px;
  /* Add padding below the heading */
}

.customcaptcha {}

.customcaptcha div {
  display: inline-block;
  margin-left: 50%;
  transform: translateX(-50%);
}

.login-main form {
  padding-top: 2rem;
}

.create-account {
  padding-left: 5px;

  text-decoration: none;
  color: #121212;
  cursor: pointer;
}

.create-account:hover {
  color: #121212;

  text-decoration: underline;
}

.forgot-password {
  text-decoration: underline;
  color: #121212;
  cursor: pointer;
}

.forgot-password:hover {
  color: #121212;
  text-decoration: none;
}