.heading {
    position: absolute;
    top: 50%;
    left: 50px;
    color: white;
    font-family: var(--bs-body-font-family);

}

.home-main {
    width: 100%;
    display: flex;
    align-items: flex-start;
    background-image: url('../../../public/colposcopy-directed-biopsy-2x.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff !important;
}

.white-heading {
    color: #fff !important;
}

strong {
    text-align: center;
}

.flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}