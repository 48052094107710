.customCol {
  display: flex;
  /* Add this to make sure child elements don't overflow */
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
  justify-content: center;


}

.customform {
  width: 100%;
  height: auto;
}

.customtitle {
  font-family: var(--bs-body-font-family);
  background-color: hsl(0, 0%, 95%);
  text-align: center;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customtitle h3 {
  font-weight: 400;
  /* Optionally, you can add margin to give some space around the h3 element */
  margin: 0;
}

.custombutton1 {
  border-radius: 0;
  background-color: #121212;
  font-family: var(--bs-body-font-family);

  height: 50px;
  border-color: #121212 !important;

  margin-bottom: 10px;
  border-radius: 5px;
}

.custombutton1:active {
  background-color: #090909 !important;
  border-color: #000000 !important;
  color: #ffffff;

}

.custombutton1:hover {
  background-color: #ffffff;
  border-color: #000000;
  color: #121212;
}


.customforms {
  font-family: 'Montserrat', sans-serif;

  margin-top: 20px;
}

/* AddQuestion.css */
.customform {
  margin-bottom: 15px;
}

.quill-editor {
  margin-bottom: 15px;
}

.tag {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 0.2em 0.6em;
  margin: 0.2em;
  border-radius: 3px;
}

.custombutton1 {
  margin-top: 15px;
}


/* .custonform:focus{
    border-color: #121212;
    outline: none;
    box-shadow: none !important;
} */

/* Tags.css */

/* Container styling */
.react-tags {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  max-width: 600px;
  background-color: #f9f9f9;
}

.react-tags__label {
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
}

.react-tags__list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.react-tags__list-item {
  display: inline-block;
}

.react-tags__tag {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.react-tags__tag:hover {
  background-color: #0056b3;
}

.react-tags__tag-name {
  margin-right: 5px;
}

.react-tags__combobox {
  position: relative;
  margin-top: 10px;
}

.react-tags__combobox-input {
  width: 100% !important;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

.react-tags__combobox-input:focus {
  border-color: #007bff;
}

.react-tags__listbox {
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  border-top: none;
  background-color: white;
  z-index: 10;
  max-height: 150px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.react-tags__listbox-option {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.react-tags__listbox-option:hover {
  background-color: #f1f1f1;
}

.react-tags__listbox-option.is-active {
  background-color: #007bff;
  color: white;
}