.about-us-img {
    border-radius: 50px;
    /* animation-delay: 5s; */
    max-width: 380px;
    height: 380px;
    margin: 0 auto;
    background-size: cover;
}

.animated {
    animation: initialAnimation 5s linear;
}

@keyframes initialAnimation {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@media (min-width: 768px) {
    .pl-0 {
        padding-left: 0;
    }
}