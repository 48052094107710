.chart-container {
    padding: 20px;
    position: relative;
    overflow-x: clip;
    overflow-y: visible;
}

.tooltip-barchart {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px 0;
    display: none;
    z-index: 100;
    pointer-events: none;
}

.tooltip-barchart div {
    padding: 0 10px;
}

.tooltip-barchart hr {
    margin: 0.25rem 0;
}