  h2 {
    margin-bottom: 20px;
  }

  .category-stats {
    margin-bottom: 40px;
  }

  .table {
    margin-top: 20px;
  }

  @media (max-width: 560px) {
    .table {
      display: block !important;
      overflow-x: scroll;
    }
  }

  .view {
    padding: 5px;
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    border-radius: 50px;
    color: #fff;
    width: 100%;
  }

  .pie-chart {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .stats-main {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .stats-table th,
  .stats-table td {
    padding: .75em;
    font-size: 1rem;
  }