.question-answers-description table {
    width: 100%;
    border-collapse: collapse;
}

.quiz-page-container {
    display: flex;
    justify-content: space-evenly;
    padding: 20px;
    align-items: flex-start;
}

.question h5 {
    text-align: justify;
}

.question h3 {
    border-bottom: 2px solid #33333380;
}

.quiz-right {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.quiz-right .alert {
    width: 100%;
}

.options div {
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    margin: 15px 0;
    transition: 300ms ease-out;
}

.quiz-buttons {
    display: flex;
    flex-wrap: wrap;
    max-height: 80vh;
    overflow-y: scroll;
    justify-content: safe;
    align-content: flex-start;
    margin-bottom: 20px;
    padding: 20px 10px 20px 20px;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
}

.quiz-buttons::-webkit-scrollbar {
    width: 10px;
}

.quiz-buttons::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
}

.quiz-buttons::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 3px;
}

.quiz-buttons button {
    margin: 6px;
    background-color: #eee;
    color: #111;
    font-size: 16px;
    border: 1px solid #ddd;
    height: 30px;
    min-width: 50px;
    border-radius: 5px;
}

.quiz-buttons .selected {
    box-shadow: var(--primary-color) 0px 0px 4px 2px;
}

.checkbox-wrapper-46:hover {
    background-color: rgb(203, 208, 244);
    ;
    transition: 300ms ease-in-out;
    cursor: pointer;
}

.checkbox-wrapper-46 input[type="checkbox"] {
    display: none;
    visibility: hidden;
}

.checkbox-wrapper-46 .cbx {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.checkbox-wrapper-46 .cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-46 .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #9098a9;
    transition: all 0.2s ease;
}

.checkbox-wrapper-46 .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #ffffff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-46 .cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #506eec;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
}

.checkbox-wrapper-46 .cbx span:last-child {
    padding-left: 8px;
    width: 95%;
}

.checkbox-wrapper-46 .cbx:hover span:first-child {
    border-color: #506eec;
}

.checkbox-wrapper-46 .inp-cbx:checked+.cbx span:first-child {
    background: #506eec;
    border-color: #506eec;
    animation: wave-46 0.4s ease;
}

.checkbox-wrapper-46 .inp-cbx:checked+.cbx span:first-child svg {
    stroke-dashoffset: 0;
}

.checkbox-wrapper-46 .inp-cbx:checked+.cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
}

@keyframes wave-46 {
    50% {
        transform: scale(0.9);
    }
}

.navigate-question {
    width: 75px;
    height: 75px;
    margin: 15px 0;
    padding: 20px;
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    border-radius: 50px;
}


.submit-answer {
    width: 200px;
    height: 75px;
    margin: 15px 0;
    padding: 20px;
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    border-radius: 50px;
    color: #fff;
}

.navigate-question:hover,
.submit-answer:hover {
    background-color: var(--hover-color);
}

.control-buttons {
    display: flex;
    justify-content: space-between;
}

.correct-answer {
    background-color: rgb(80, 222, 40) !important;
}

.wrong-answer {
    background-color: rgb(205, 72, 72) !important;
}

.answer-disabled {
    pointer-events: none;
    background-color: #eee;
    opacity: 0.65;
}

.answer-option {
    background-color: #eee;
}

.answer-option:hover {
    background-color: #ddd;
}

.answer-correct {
    color: #155724;
    background-color: #d4edda;
    pointer-events: none;
}

.answer-incorrect {
    color: #571515;
    background-color: #edd4d4;
    pointer-events: none;
}

.text-justify {
    text-align: justify;
}

.MsoNormalTable {
    margin-bottom: 2.5rem;
    overflow-x: auto;
    display: block;
    border: none !important;
}

.MsoNormalTable>tbody>tr>td:nth-child(2n+1) {
    --bs-table-color-type: #000;
    --bs-table-bg-type: rgba(0, 0, 0, 0.05);
    --bs-table-bg-state: initial;
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.MsoNormalTable>thead>tr>td {
    border-color: white !important;
}

.MsoNormalTable>thead>tr>td:nth-child(5n+1) {
    background-color: #538135;
    color: white;
}

.MsoNormalTable>thead>tr>td:nth-child(5n+2) {
    background-color: #2E74B5;
    color: white;
}

.MsoNormalTable>thead>tr>td:nth-child(5n+3) {
    background-color: #7030A0;
    color: white;
}

.MsoNormalTable>thead>tr>td:nth-child(5n+4) {
    background-color: #BF8F00;
    color: white;
}

.MsoNormalTable>thead>tr>td:nth-child(5n+5) {
    background-color: #1F3864;
    color: white;
}


.MsoNormalTable td {
    padding: 0.75rem !important;
    border: 0.5px solid black;
}

.MsoNormalTable tr {
    border: none;
}

.MsoNormalTable td:not(:has(p)) {
    border-top: none !important;
}

.MsoNormalTable tr,
.MsoNormalTable td {
    border-right: none !important;
    border-left: none !important;
    border-bottom: none !important;
}

.MsoNormalTable tr:last-child td {
    border-bottom: 0.5px solid black;
}

.topic-essay ul,
.topic-essay ol {
    padding-left: 1.25rem;
}

.topic-essay li {
    line-height: 1.5rem !important;
}

.topic-essay p {
    margin-left: auto !important;
    text-indent: 0 !important;
}

.topic-essay span {
    font-family: var(--bs-body-font-family) !important;
}

.topic-essay h1 {
    font-size: 2.25rem;
    font-weight: 700;
}

.topic-essay ul ul,
.topic-essay ol ul {
    list-style-type: "— ";
    color: #555 !important;
}

.topic-essay h3,
.topic-essay h4,
.topic-essay h3 span,
.topic-essay h4 span {
    font-size: 1.5rem;
    font-weight: 700 !important;
}

.MuiChartsTooltip-table td {
    border: none;
    text-align: center;
}

/* Base settings for the tooltip to ensure it appears above other content */
.base-Popper-root {
    position: absolute;
    color: #333333;
    font-family: var(--bs-body-font-family);
    z-index: 1000;
    transform: translate(580px, 389px);
}

/* Styling the container inside the tooltip for additional control */
.MuiChartsTooltip-root {
    display: block;
    /* Use block to manage spacing and padding effectively */
}

/* Style the table for clear data presentation */
.MuiChartsTooltip-table {
    width: 100%;
    /* Full width of the container */
    border-spacing: 0;
    /* Removes space between cells */
}

/* Each row styling for separation and alignment */
.MuiChartsTooltip-row {
    line-height: 1.6;
    /* Better line height for readability */
    border-bottom: 1px solid #eaeaea;
    /* Light border for subtle row separation */
}

.MuiChartsTooltip-row:last-child {
    border-bottom: none;
    /* No border at the bottom of the last row */
}

/* Generic cell styling for consistent text formatting */
.MuiChartsTooltip-cell {
    padding: 4px 8px;
    /* Uniform padding for all cells */
    text-align: left;
    /* Left-align all text */
}

/* Mark cell styling */
.MuiChartsTooltip-markCell {
    padding-right: 8px;
    /* Space between mark and text */
    text-align: center;
    /* Center the mark for aesthetics */
}

/* Mark representation styling */
.MuiChartsTooltip-mark {
    width: 10px;
    /* Width of the mark */
    height: 10px;
    /* Height of the mark */
    background-color: #506eec;
    /* Example color, adjust to match your chart's scheme */
    border-radius: 50%;
    /* Circular mark */
}

/* Label cell styling for emphasis */
.MuiChartsTooltip-labelCell {
    font-weight: bold;
    /* Bold for emphasis */
}

/* Value cell styling */
.MuiChartsTooltip-valueCell {
    text-align: right;
    /* Right-align for clear separation of label and value */
}