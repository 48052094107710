/* Main Header Styles */
.main-header h1 {
    margin: 0;
    font-size: 24px;
    font-family: var(--bs-body-font-family);
    color: #fff;
    cursor: pointer;
}

.main-header small {
    font-size: 12px;
    color: gray;
    /* Using a more specific color instead of 'grey' */
    display: block;
}

/* Navbar Styles */
.navbar-light .navbar-nav .nav-link {
    color: var(--primary-color);
    margin-right: 20px;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: var(--hover-color);
}

/* Utilities */
.menu-right {
    justify-content: flex-end;
}

.menu-right .btn {
    background-color: var(--primary-color);
    border: none;
    cursor: pointer;
    padding: 0.5em 1em;
    border-radius: 10px;
    margin-right: 20px;
}

.menu-right .btn:hover {
    background-color: var(--hover-color);
    border: none;
}

.hamburger {
    cursor: pointer;
}

.hamburger input {
    display: none;
}

.hamburger svg {
    /* The size of the SVG defines the overall size */
    height: 2em;
    /* Define the transition for transforming the SVG */
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
    fill: none;
    stroke: white;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3;
    /* Define the transition for transforming the Stroke */
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
    stroke-dasharray: 12 63;
}

.hamburger input:checked+svg {
    transform: rotate(-45deg);
}

.hamburger input:checked+svg .line-top-bottom {
    stroke-dasharray: 20 300;
    stroke-dashoffset: -32.42;
}

.header-line {
    --bs-gutter-x: 1.5rem;
    width: calc(100% - (var(--bs-gutter-x)* .5) - (var(--bs-gutter-x)* .5));
    border-bottom: 15px solid #e7700d;
    max-width: calc(960px - (var(--bs-gutter-x)* .5) - (var(--bs-gutter-x)* .5));
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
}